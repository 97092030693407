export default Object.freeze({
  NEW: "new",
  EDIT: "edit",
  INVALIDE: "INVALIDE",
  INVALIDE_LIBELLE: "Invalide",
  POSTE: "POSTE",
  VALIDE: "VALIDE",
  VALIDE_LIBELLE: "Valide",
  PUBLIE: "PUBLIE",
  LIBELLE_INVALIDES: "Invalidés",
  LIBELLE_POSTES: "Postés",
  LIBELLE_VALIDES: "Validés",
  LIBELLE_PUBLIES: "Publiés",
  LIBELLE_INVALIDER: "Invalider",
  LIBELLE_POSTER: "Poster",
  LIBELLE_VALIDER: "Valider",
  LIBELLE_PUBLIER: "Publier",
  CREE: "CREE",
  EN_COURS_DE_PREPARATION: "EN_COURS_DE_PREPARATION",
  EN_ATTENTE_DE_LIVRAISON: "EN_ATTENTE_DE_LIVRAISON",
  EN_COURS_DE_LIVRAISON: "EN_COURS_DE_LIVRAISON",
  RETOURNEE: "RETOURNEE",
  LIVREE: "LIVREE",
  ANNULEE: "ANNULEE",
  LIBELLE_CREE: "Crée",
  LIBELLE_EN_COURS_DE_PREPARATION: "En cours de preparation",
  LIBELLE_EN_ATTENTE_DE_LIVRAISON: "En attente de livraison",
  LIBELLE_EN_COURS_DE_LIVRAISON: "En cours de livraison",
  LIBELLE_RETOURNEE: "Retournée",
  LIBELLE_LIVREE: "Livrée",
  LIBELLE_ANNULEE: "Annulée",
  EN_COURS_DE_VALIDATION: "EN_COURS_DE_VALIDATION",
  EN_COURS_DE_VALIDATION_LIBELLE: "En cours de validation",
  VALIDEE: "VALIDEE",
  LIVRAISON_EN_COURS: "LIVRAISON_EN_COURS",
  TERMINEE: "TERMINEE",
  CASH: "CASH",
  CB: "CB",
  ANNULE: "ANNULE",
  FINALISE: "FINALISE",
  REMBOURSE: "REMBOURSE",
  ORDER_STATE: "orderState",
  DELIVERY_STATE: "deliveryState",
  PAYMENT_MODE: "paymentMode",
  PAYMENT_STATE: "paymentState",
  ORDER_STATE_LIBELLE: "Statuts de la commande",
  DELIVERY_STATE_LIBELLE: "Statuts de la livraison",
  PAYMENT_MODE_LIBELLE: "Modes de paiement",
  PAYMENT_STATE_LIBELLE: "Statuts de paiement",
});
