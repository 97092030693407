import db from "./../../firebaseInit";
import NodesName from "./nodesName";
import NodesFieldName from "./nodesFieldName";

export default {
  /**
   * Ajouter ou mise à jour des états des produits par type de produit en base
   * @param {La route} router
   * @param {Type du produit} type
   * @param {*} likeNewState
   * @param {*} veryGoodState
   * @param {*} goodState
   * @param {*} correctState
   */
  saveStatesOfTypeOfProducts: (
    router,
    type,
    likeNewState,
    veryGoodState,
    goodState,
    correctState
  ) => {
    db.collection(NodesName.PRODUCT_STATES)
      .where(NodesFieldName.UNIQUE_ID, "==", type)
      .limit(1)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.size == 0) {
          db.collection(NodesName.PRODUCT_STATES)
            .add({
              uniqueId: type,
              likeNewState: likeNewState,
              veryGoodState: veryGoodState,
              goodState: goodState,
              correctState: correctState,
            })
            .then(() => {
              router.push({ name: "catalogSettings" });
            })
            .catch(function (error) {
              console.error("Error adding document: ", error);
            });
        } else {
          querySnapshot.forEach((doc) => {
            db.collection(NodesName.PRODUCT_STATES)
              .doc(doc.id)
              .update({
                uniqueId: type,
                likeNewState: likeNewState,
                veryGoodState: veryGoodState,
                goodState: goodState,
                correctState: correctState,
              })
              .then(() => {
                router.push({ name: "catalogSettings" });
              })
              .catch(function (error) {
                console.error("Error adding document: ", error);
              });
          });
        }
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  },
  /**
   * @param {La référence de la collection} ref
   * @returns La taille d'une collection en base
   */
  getCount: (ref) => {
    return ref
      .where(NodesFieldName.DELETED, "==", false)
      .get()
      .then((querySnapshot) => {
        return querySnapshot.size;
      });
  },
  /**
   * @param {La référence de la collection} ref
   * @returns La taille d'une collection en base
   */
  getGeneralStats: () => {
    return db.collection(NodesName.GENERAL_STATS).get();
  },
  /**
   * @param {La référence de la collection} ref
   * @returns La taille d'une collection en base
   */
  getCountUserProductBySubCategory: async (collection, subCategory) => {
    const querySnapshot = await db
      .collection(collection)
      .where("subCategory", "==", subCategory)
      .where(NodesFieldName.DELETED, "==", false)
      .get();
    return querySnapshot.size;
  },
  /**
   * @param {Marque du produit} mark
   * @param {Type du produit} type
   * @param {Le dernier produit de la liste} lastVisibleProduct
   * @returns Une liste de produits
   */
  getProductsFromCatalog: async (
    mark,
    type,
    lastVisibleProduct,
    itemsPerPage,
    totalProductCount
  ) => {
    var limitPerPage = itemsPerPage == -1 ? totalProductCount : itemsPerPage;
    return lastVisibleProduct
      ? await db
          .collection(NodesName.PRODUCT_CATALOG)
          .doc(mark)
          .collection(type)
          .where(NodesFieldName.DELETED, "==", false)
          .orderBy(NodesFieldName.RELEASE_DATE, "desc")
          .startAfter(lastVisibleProduct)
          .limit(limitPerPage)
          .get()
      : await db
          .collection(NodesName.PRODUCT_CATALOG)
          .doc(mark)
          .collection(type)
          .where(NodesFieldName.DELETED, "==", false)
          .orderBy(NodesFieldName.RELEASE_DATE, "desc")
          .limit(limitPerPage)
          .get();
  },
  getDocumentsWithPagination: async (
    query,
    lastVisibleProduct,
    itemsPerPage,
    totalProductsCount
  ) => {
    var limitPerPage = itemsPerPage == -1 ? totalProductsCount : itemsPerPage;

    return lastVisibleProduct
      ? await query
          .where(NodesFieldName.DELETED, "==", false)
          .orderBy(NodesFieldName.CREATION_DATE, "desc")
          .startAfter(lastVisibleProduct)
          .limit(limitPerPage)
          .get()
      : await query
          .where(NodesFieldName.DELETED, "==", false)
          .orderBy(NodesFieldName.CREATION_DATE, "desc")
          .limit(limitPerPage)
          .get();
  },
  getFaqWithPagination: async (
    query,
    lastVisibleFaq,
    itemsPerPage,
    totalFaqCount
  ) => {
    var limitPerPage = itemsPerPage == -1 ? totalFaqCount : itemsPerPage;

    return lastVisibleFaq
      ? await query
          .where(NodesFieldName.DELETED, "==", false)
          .orderBy(NodesFieldName.ORDER_FAQ, "asc")
          .startAfter(lastVisibleFaq)
          .limit(limitPerPage)
          .get()
      : await query
          .where(NodesFieldName.DELETED, "==", false)
          .orderBy(NodesFieldName.ORDER_FAQ, "asc")
          .limit(limitPerPage)
          .get();
  },

  getUsersListeWithPagination: async (
    query,
    lastVisibleUser,
    itemsPerPage,
    totalUsersCount
  ) => {
    var limitPerPage = itemsPerPage == -1 ? totalUsersCount : itemsPerPage;

    return lastVisibleUser
      ? await query
          .where(NodesFieldName.DELETED, "==", false)
          .startAfter(lastVisibleUser)
          .limit(limitPerPage)
          .get()
      : await query
          .where(NodesFieldName.DELETED, "==", false)
          .limit(limitPerPage)
          .get();
  },
  // Api Token Pour MercaNews Publication
  GetPAYMEEInformations: async () => {
    var Paymeeaccount;
    await db
      .collection(NodesName.GENERAL_SETTINGS)
      .doc(NodesName.PAYMENT_SOLUTION)
      .get()
      .then((DocumentSnapshot) => {
        Paymeeaccount = {
          APIKEY: DocumentSnapshot.data().API_KEY,
          PaymentGatewayLink: DocumentSnapshot.data().PaymentGatewayLink,
          accountNumber: DocumentSnapshot.data().accountNumber,
          checkPaymentLink: DocumentSnapshot.data().checkPaymentLink,
          initiatePaymentLink: DocumentSnapshot.data().initiatePaymentLink,
          payementFileLink: DocumentSnapshot.data().payementFileLink,
          payementResultFileLink:
            DocumentSnapshot.data().payementResultFileLink,
        };
      });
    return Paymeeaccount;
  },
  // Api Token Pour MercaNews Publication
  GetSocialMediaInformations: async () => {
    var socialMedia;
    await db
      .collection(NodesName.GENERAL_SETTINGS)
      .doc(NodesName.SOCIAL_MEDIA)
      .get()
      .then((DocumentSnapshot) => {
        socialMedia = {
          token: DocumentSnapshot.data().apiFacebook,
          idFacebook: DocumentSnapshot.data().idFacebook,
          idInstagram: DocumentSnapshot.data().idInstagram,
          publieFacebook: DocumentSnapshot.data().publieFacebook,
          publieInstagram: DocumentSnapshot.data().publieInstagram,
        };
      });
    return socialMedia;
  },
  // Recuperation du logoMercanews
  GetLogoMercanews: async () => {
    var logo;
    await db
      .collection(NodesName.GENERAL_SETTINGS)
      .doc(NodesName.LOGOMERCANEWS)
      .get()
      .then((DocumentSnapshot) => {
        logo = DocumentSnapshot.data().lien;
      });
    return logo;
  },
  // Avoire detaille des utilisateurs a partir de l'id
  /**
   * @param {L'ID du utilisateurs} ref
   * @returns detaille utilisateurs
   */
  getUserInformation: async (userId) => {
    var userName;

    await db
      .collection(NodesName.USERS_DETAILS)
      .doc(userId)
      .get()
      .then((doc) => {
        userName = doc.data().userName;
      });
    return userName;
  },
  getNameCategory: async (uniqueId) => {
    var name;
    await db
      .collection(NodesName.PRODUCT_TO_SHARE_CATEGORIES)
      .doc(uniqueId)
      .get()
      .then((doc) => {
        name = doc.data().libelle;
      });
    return name;
  },

  getNameSubCategoryArray: async (uniqueId) => {
    var subCategoryLibelle = [];
    for (var i = 0; i < uniqueId.length; i++) {
      await db
        .collection(NodesName.PRODUCT_TO_SHARE_SUBCATEGORIES)
        .doc(uniqueId[i])
        .get()
        .then((doc) => {
          subCategoryLibelle[i] = doc.data().libelle;
        });
    }
    return subCategoryLibelle;
  },

  //Recuperation nom et prenom des users
  getUserInformationName: async (userId) => {
    console.log(userId);
    var infoName;

    await db
      .collection(NodesName.USERS_DETAILS)
      .doc(userId)
      .get()
      .then((doc) => {
        infoName = {
          firstName: doc.data().firstName,
          lastName: doc.data().lastName,
        };
      });
    return infoName;
  },
  //Recuperation de la taxe mercarue
  getTaxe: async () => {
    var mercarueTax;
    await db
      .collection(NodesName.GENERAL_SETTINGS)
      .doc(NodesName.MERCARUE)
      .get()
      .then((doc) => {
        mercarueTax = doc.data().taxe_percent;
      });
    return mercarueTax;
  },
};
