<template>
  <div id="catalogSettings">
    <v-container class="lighten-5">
      <v-container>
        <dashboard-core-app-bar />
      </v-container>
      <v-row style="margin-top: 20px">
        <v-breadcrumbs :items="items" divider=">"></v-breadcrumbs>
        <v-col cols="12" md="12">
          <v-card class="pa-2" outlined tile>
            <v-card-title>États du produit</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="4">
                  <v-autocomplete
                    :items="productstate"
                    item-text="type"
                    item-value="typeExternalId"
                    label="Sous Categories de produit"
                    clearable
                    hide-details
                    hide-selected
                    @input="redirectToManageStates"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12">
          <v-card class="pa-2" outlined tile>
            <v-card-title>Catalogue de produits</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="4">
                  <v-dialog width="500">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="#19b4bf" dark v-bind="attrs" v-on="on">
                        Ajouter un produit
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="headline" style="color: #19b4bf">
                        Ajoute de produit
                      </v-card-title>
                      <v-card-text>
                        <v-row>
                          <v-col md="12">
                            <v-autocomplete
                              :items="categories"
                              item-text="categorie"
                              item-value="categorieId"
                              label="Categorie de produits"
                              chips
                              clearable
                              hide-details
                              hide-selected
                              v-model="selectCategorie"
                              @input="onProductcategorieChange"
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                        <v-row v-if="selectCategorie">
                          <v-col md="12">
                            <v-autocomplete
                              :items="productTypes"
                              item-text="type"
                              return-object
                              label="Sous Categories de produit"
                              chips
                              clearable
                              hide-details
                              hide-selected
                              v-model="productTypeForAddProduct"
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="#19b4bf"
                          text
                          @click="redirectToAddProduct"
                          :disabled="!productTypeForAddProduct"
                        >
                          Choisir
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <v-autocomplete
                    :items="categories"
                    item-text="categorie"
                    item-value="categorieId"
                    label="Categorie de produits"
                    chips
                    clearable
                    hide-details
                    hide-selected
                    v-model="categorie"
                    @input="onProductcategorieChange"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="4">
                  <v-autocomplete
                    :items="productTypes"
                    item-text="type"
                    item-value="typeExternalId"
                    label="Sous Categories de produits"
                    chips
                    :disabled="!this.categorie"
                    clearable
                    hide-details
                    hide-selected
                    v-model="type"
                    @input="onProductTypesChange"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="4">
                  <v-autocomplete
                    :items="productMarks"
                    label="Marques de produits"
                    item-text="Mark"
                    item-value="MarkExternalId"
                    chips
                    clearable
                    hide-details
                    hide-selected
                    :disabled="!this.categorie"
                    v-model="mark"
                    @input="onProductMarksChange"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-card>
                <v-card-title> Produits </v-card-title>
                <v-data-table
                  :footer-props="{
                    'items-per-page-options': [5, 10, 15, 30],
                  }"
                  :headers="headers"
                  :items="products"
                  :search="search"
                  :server-items-length="totalProductsCount"
                  :options.sync="options"
                  :loading="loading"
                  class="elevation-1"
                >
                  <template v-slot:item="row">
                    <tr>
                      <td class="d-block d-sm-table-cell">
                        <v-btn @click="redirectToEditProduct(row.item)" text>
                          <span class="mr-2">{{ row.item.product_id }}</span>
                        </v-btn>
                      </td>
                      <td class="d-block d-sm-table-cell">
                        {{ row.item.mark }}
                      </td>
                      <td class="d-block d-sm-table-cell">
                        {{ row.item.type }}
                      </td>
                      <td class="d-block d-sm-table-cell">
                        {{ row.item.name }}
                      </td>
                      <td class="d-block d-sm-table-cell">
                        {{ row.item.date }}
                      </td>
                      <td v-if="checkRoleState" class="d-block d-sm-table-cell">
                        <v-btn
                          class="mx-2"
                          fab
                          dark
                          small
                          color="red"
                          @click="deleteProduct(row)"
                        >
                          <v-icon dark>mdi-delete</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import db from "../../../firebaseInit";
import NodesName from "../../utils/nodesName";
import NodesFieldName from "../../utils/nodesFieldName";
import FunctionsUtils from "../../utils/functions";
import Constants from "../../utils/constants";
import FirebaseDB from "../../utils/firebaseDB";
import moment from "moment";
import firebase from "firebase";

export default {
  name: "catalogSettings",
  components: {
    DashboardCoreAppBar: () =>
      import("../../materialDashboard/components/core/AppBar.vue"),
  },
  data() {
    return {
      subcategoryId: null,
      stateCategorie: null,
      products: [],
      totalProducts: [],
      search: "",
      productMarks: [],
      categories: [],
      options: {},
      productTypes: [],
      productTypeForAddProduct: null,
      type: null,
      mark: null,
      selectCategorie: null,
      categorie: null,
      totalProductsCount: 0,
      loading: true,
      checkRoleState: false,
      lastVisibleProduct: null,
      productstate: [],
      items: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/",
        },
      ],
    };
  },
  async beforeRouteEnter(to, from, next) {
    // Condition Admin pour avoir la possibilité de supprimer de produits
    firebase
      .auth()
      .currentUser.getIdTokenResult()
      .then((tokenResult) => {
        if (tokenResult.claims.admin) {
          next((vm) => {
            vm.checkRoleState = true;
          });
        }
      });
    var mark = to.params.mark
      ? to.params.mark
      : localStorage.getItem("markForCatalogProduct");
    var type = to.params.type
      ? to.params.type
      : localStorage.getItem("typeForCatalogProduct");

    if (mark && type) {
      next((vm) => {
        vm.mark = localStorage.getItem("markForCatalogProduct");
        vm.type = localStorage.getItem("typeForCatalogProduct");
        vm.loading = false;
      });
    } else {
      next((vm) => (vm.loading = false));
    }
  },
  methods: {
    onProductcategorieChange: function (event) {
      this.type = null;
      this.mark = null;
      // Récupérer les marques de produit
      db.collection(NodesName.PRODUCT_TO_SHARE_MARK)
        .where(NodesFieldName.CATEGORY, "array-contains", event)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.productMarks.push({
              Mark: doc.data().name,
              MarkExternalId: doc.data().externalId,
            });
          });
        });
      // Récupérer les types de produit
      this.productTypes = [];
      db.collection(NodesName.PRODUCT_TO_SHARE_SUBCATEGORIES)
        .where(NodesFieldName.CATEGORY, "==", event)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.productTypes.push({
              type: doc.data().libelle,
              typeExternalId: doc.data().externalId,
              typeId: doc.data().uniqueId,
            });
          });
        });
    },
    onProductTypesChange: function (event) {
      this.totalProducts = [];
      this.products = [];
      this.lastVisibleProduct = null;
      this.loadProducts(this.mark, event);
    },
    onProductMarksChange: function (event) {
      this.totalProducts = [];
      this.products = [];
      this.lastVisibleProduct = null;
      this.loadProducts(event, this.type);
    },
    redirectToManageStates: function (event) {
      var typeSelected = event;

      if (typeSelected) {
        var redirectTo =
          FunctionsUtils.getRedirectionToManageStatesProduct(typeSelected);
        this.$router.push({ name: redirectTo, params: { type: typeSelected } });
      } else {
        alert("The type of product is empty !"); //TODO : modifier en snackbars ou toasts
      }
    },
    deleteProduct(row) {
      if (this.checkRoleState) {
        if (confirm("Are you sure ?")) {
          db.collection(NodesName.PRODUCT_CATALOG)
            .doc(row.item.mark)
            .collection(row.item.type)
            .doc(row.item.product_id)
            .update({ deleted: true })
            .then((querySnapshot) => {
              this.products.splice(row.index, 1);
            })
            .catch(function (error) {
              console.log("Error delete document:", error);
            });
        }
      }
    },
    redirectToAddProduct() {
      var typeSelected = this.productTypeForAddProduct.typeExternalId;
      if (typeSelected) {
        var redirectTo =
          FunctionsUtils.getRedirectionToManageNewObject(typeSelected);
        var routeName = this.$router.resolve({
          name: redirectTo,
          params: {
            purpose: Constants.NEW,
            type: typeSelected,
            category: this.selectCategorie,
            subcategory: this.productTypeForAddProduct.typeId,
          },
        });
        if (routeName.resolved.matched.length > 0) {
          this.$router.push({
            name: redirectTo,
            params: {
              purpose: Constants.NEW,
              type: typeSelected,
              category: this.selectCategorie,
              subcategory: this.productTypeForAddProduct.typeId,
            },
          });
        } else {
          this.$router.push({
            name: "manage-highTech",
            params: {
              purpose: Constants.NEW,
              type: typeSelected,
              category: this.selectCategorie,
              subcategory: this.productTypeForAddProduct.typeId,
            },
          });
        }
      } else {
        alert("The type of product is empty !"); //TODO : modifier en snackbars ou toasts
      }
    },
    async redirectToEditProduct(item) {
      // get MarkID from mark Externel ID(to edit family)
      await db
        .collection(NodesName.PRODUCT_TO_SHARE_SUBCATEGORIES)
        .where(NodesFieldName.EXTERNAL_ID, "==", item.type)
        .limit(1)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.subcategoryId = doc.id;
          });
        });

      var redirectTo = FunctionsUtils.getRedirectionToManageNewObject(
        item.type
      );
      var routeName = this.$router.resolve({
        name: redirectTo,
        params: { purpose: Constants.NEW, type: item.type, mark: item.mark },
      });
      if (routeName.resolved.matched.length > 0) {
        this.$router.push({
          name: redirectTo,
          params: {
            purpose: Constants.EDIT,
            product_id: item.product_id,
            mark: item.mark,
            type: item.type,
            typeId: this.subcategoryId,
          },
        });
      } else {
        this.$router.push({
          name: "manage-highTech",
          params: {
            purpose: Constants.EDIT,
            product_id: item.product_id,
            mark: item.mark,
            type: item.type,
            typeId: this.subcategoryId,
          },
        });
      }
    },
    async loadProducts(markSelected, typeSelected) {
      this.loading = true;
      localStorage.setItem("markForCatalogProduct", markSelected);
      localStorage.setItem("typeForCatalogProduct", typeSelected);
      this.mark = localStorage.getItem("markForCatalogProduct");
      this.type = localStorage.getItem("typeForCatalogProduct");
      if (this.mark && this.type) {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        var result = await FirebaseDB.getCount(
          db
            .collection(NodesName.PRODUCT_CATALOG)
            .doc(this.mark)
            .collection(this.type)
        );
        this.totalProductsCount = result;
        if (this.totalProducts.length < this.totalProductsCount) {
          var querySnapshot = await FirebaseDB.getProductsFromCatalog(
            this.mark,
            this.type,
            this.lastVisibleProduct,
            itemsPerPage,
            this.totalProductsCount
          );

          let i = 0;
          querySnapshot.forEach((product) => {
            if (Object.entries(product.data()).length !== 0) {
              const found = this.totalProducts.some(
                (el) => el.product_id === product.id
              );
              if (!found) {
                this.totalProducts.push({
                  mark: this.mark,
                  type: this.type,
                  product_id: product.id,
                  name: product.data().name,
                  date: moment(
                    new Date(product.data().releaseDate.seconds * 1000)
                  ).format("L"),
                });
                i++;
                if (querySnapshot.size == i) {
                  this.lastVisibleProduct = product;
                }
              }
            }
          });
        }

        this.products = FunctionsUtils.customSortDataTable(
          this.totalProducts,
          sortBy,
          sortDesc
        );

        this.products = FunctionsUtils.getItemsPerPageForDataTable(
          this.totalProducts,
          itemsPerPage,
          page
        );
      }
      this.loading = false;
    },
  },
  created() {
    // Récupérer les type pour state de produit

    db.collection(NodesName.PRODUCT_TO_SHARE_SUBCATEGORIES)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.productstate.push({
            type: doc.data().libelle,
            typeExternalId: doc.data().externalId,
          });
        });
      });
    // Récupérer les categories de produit
    db.collection(NodesName.PRODUCT_TO_SHARE_CATEGORIES)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.categories.push({
            categorie: doc.data().name,
            categorieId: doc.data().uniqueId,
          });
        });
      });
  },

  computed: {
    headers() {
      // To do Amelioration du boucle if
      if (this.checkRoleState) {
        return [
          { text: "ID", value: "product_id" },
          { text: "Mark", value: "mark" },
          { text: "Sous Categorie", value: "type" },
          { text: "Name", value: "name" },
          { text: "Date de Sortie", value: "date" },
          { text: "Manage", value: "" },
        ];
      } else
        return [
          { text: "ID", value: "product_id" },
          { text: "Mark", value: "mark" },
          { text: "Sous Categorie", value: "type" },
          { text: "Name", value: "name" },
          { text: "Date de Sortie", value: "date" },
        ];
    },
  },
  watch: {
    options: {
      handler() {
        this.loadProducts(this.mark, this.type);
      },
      deep: true,
    },
  },
  mounted() {
    this.loadProducts(this.mark, this.type);
  },
};
</script>
